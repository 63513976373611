<template>
  <v-list>
    <h1 class="text-center">Уведомления</h1>
    <v-col cols="12" v-for="(note, i) in notes_detail" :key="i">
      <v-card class="notificate">
        <v-card-title class="text-h5">
          <strong>
            {{ i + 1 }}.
            {{ note.detail.bill__client__name }}
            {{ note.detail.bill__client__second_name }}
            {{ note.detail.bill__client__surname }}
          </strong>
        </v-card-title>
        <v-card-subtitle><strong class="text-button">{{ note.note.date_notificate }} - {{
            note.note.status_note
          }}</strong></v-card-subtitle>

        <v-card-text>
          <strong>Заканчивается:</strong>
          <p>"{{ note.detail.good__name }}", {{ note.detail.good__price }} руб.
            <v-chip
                color="blue" class="ma-2" text-color="white"
            >{{ note.detail.quantity }} шт.
            </v-chip>
          <p></p>

          <p><strong>Комментарий:</strong></p>
          Дата покупки: {{ note.detail.bill__created_at.slice(0, 10) }}

          <p>{{ note.note.description }}</p>

        </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark :href="'tel:' + note.detail.bill__client__phone">
            <v-icon left>
              mdi-phone
            </v-icon>
            Позвонить
          </v-btn>

<!--          <v-btn color="yellow">-->
<!--            <v-icon left>mdi-pencil</v-icon>-->
<!--          </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-col>

  </v-list>
</template>

<script>
import HTTP from "../api";

export default {
  data() {
    return {
      notes: null,
      notes_detail: [],
    };
  },
  computed: {},
  methods: {},
  mounted() {
    HTTP
        .get(`sales/notificate/?days=${this.$route.params.days}`)
        .then(response => {
          this.notes = response.data;
          for (let detail of this.notes) {
            HTTP
                .get(`sales/notificate/${detail.id}/`)
                .then(response => {
                  this.notes_detail.push(
                      {
                        note: detail,
                        detail: response.data.detail_bill,
                      }
                  );
                });
            console.log('detail###:', detail)
            console.log('NOTES CHANGE###:', this.notes_detail)
          }
        });
  },
}
</script>

<style scoped>
.notificate {
  border-left: 5px solid #ffb946;
  /*background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.64) 0%, rgba(255, 240, 230, 0.65) 100%);*/
}
</style>