<template>
    <v-row class="text-center">
      <v-col>
        <h1 class="display-1 font-weight-bold mb-3">
          Поиск клиента
        </h1>
        <v-autocomplete
            auto-select-first
            clearable
            deletable-chips
            filled
            rounded
            label="Поиск клиента"
            placeholder="Для поиска введите любой символ"
            :items="CLIENTS"
            item-text="full_name_phone"
            return-object
        >

          <template v-slot:item="{ parent, item }">
            <a href="" @click.prevent="linkClient(item.id)" @keypress.enter="linkClient(item.id)">
            <v-list-item-content>
              <!--Highlight output item.name-->
              <v-list-item-title
                  v-html="`
                  ${parent.genFilteredText(item.full_name_phone)}
                  `"
              ></v-list-item-title>
            </v-list-item-content>
            </a>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col
          class="mb-5"
          cols="12"
      >
        <h2 class="display-1 font-weight-bold mb-3">
          Календарь звонков
        </h2>
        <v-row dense>
          <v-col cols="6">
            <v-card class="today" @click="notificateLink(0)">
              <v-card-title class="text-h5">Сегодня</v-card-title>
              <v-card-text class="text-h1 font-weight-bold">{{ notes_today.length }}</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card class="tommorow" @click="notificateLink(1)">
              <v-card-title class="text-h5">Завтра</v-card-title>
              <v-card-text class="text-h1 font-weight-bold">{{ notes_tommorow.length }}</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card class="week" @click="notificateLink(7)">
              <v-card-title class="text-h5">Неделя</v-card-title>
              <v-card-text class="text-h1 font-weight-bold">{{ notes_week.length }}</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card class="month" @click="notificateLink(30)">
              <v-card-title class="text-h5">Месяц</v-card-title>
              <v-card-text class="text-h1 font-weight-bold">{{ notes_month.length }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <AppButtonAdd />
      </v-col>
    </v-row>
</template>

<script>
import AppButtonAdd from "../components/AppButtonAdd";
import {mapActions, mapGetters} from 'vuex'
import HTTP from "../api";

  export default {
    name: 'Home',
    components: {
      AppButtonAdd,
    },
    data: () => ({
      panel: [0],
      clients: null,
      notes_today: [],
      notes_tommorow: [],
      notes_week: [],
      notes_month: [],

    }),
    computed: {
      ...mapGetters([
        'CLIENTS',
      ])
    },
    methods: {
      ...mapActions([
        'GET_CLIENTS_FROM_API',
      ]),
      linkClient(id) {
        this.$router.push(`clients/${id}`)
      },

      notificateLink(param) {
        this.$router.push(`notificates/${param}`)
      },
    },
    mounted() {
      this.GET_CLIENTS_FROM_API()

      HTTP
          .get('sales/notificate/')
          .then(response => {
              this.notes_month = response.data;
              console.log('### MONTH', this.notes_month);
          });

      HTTP
          .get('sales/notificate/?days=7')
          .then(response => {
            this.notes_week = response.data;
            console.log('### WEEK', this.notes_week);
          });

      HTTP
          .get('sales/notificate/?days=1')
          .then(response => {
            this.notes_tommorow = response.data;
            console.log('### WEEK', this.notes_tommorow);
          });

      HTTP
          .get('sales/notificate/?days=0')
          .then(response => {
            this.notes_today = response.data;
            console.log('### TODAY', this.notes_today);
          });
    },
  }
</script>

<style scoped>
.today {border-left: 5px solid #ffb946;}
.tommorow {border-left: 5px solid #79d42e;}
.week {border-left: 5px solid #885af8;}
.month {border-left: 5px solid #fb007c;}
</style>
