<template>
  <v-data-table
      :headers="headers"
      :items="goods"
      sort-by="calories"
      class="elevation-1"
      items-per-page="50"
  >
    <template v-slot:top>
      <div class="goods">
        <h1 class="text-center">Продукция</h1>
      </div>
      <v-toolbar
          flat
      >
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск (в разработке)"
            single-line
            hide-details
        ></v-text-field>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        v-model="editedItem.name"
                        label="Название"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.flavor"
                        label="Запах"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.volume"
                        label="Объем"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.price"
                        label="Цена"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.type"
                        label="Тип"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.article"
                        label="Артукул"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.exp_date"
                        label="Срок испарения"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Отмена
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import HTTP from "../api";

export default {
  data() {
    return {
      goods: [],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ТМ', align: 'start', value: 'brand.name', sortable: false, },
        { text: 'Артикул', value: 'article' },
        { text: 'Название', value: 'name' },
        { text: 'Объем/Вес', value: 'volume.name' },
        { text: 'Тип', value: 'type.name' },
        { text: 'Испарение, дн.', value: 'exp_date' },
        { text: 'Цена, руб.', value: 'price' },
        { text: 'Действие', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      search: '',
    };
  },
  mounted() {
    HTTP
        .get('goods/')
        .then(response => (this.goods = response.data));
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Создать' : 'Редактировать'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  methods: {
    editItem (item) {
      this.editedIndex = this.goods.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.goods[this.editedIndex], this.editedItem)
      } else {
        this.goods.push(this.editedItem)
      }
      this.close()
    },
  },

}
</script>
